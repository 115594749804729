import React from 'react';
import './MenuBackground.scss';

const MenuBackground = () => {
  return (
    <div className="menu-background">
      <div className="menu-background__item"></div>
      <div className="menu-background__item"></div>
      <div className="menu-background__item"></div>
      <div className="menu-background__item"></div>
      <div className="menu-background__item"></div>
      <div className="menu-background__item"></div>
    </div>
  );
};

export default MenuBackground;
