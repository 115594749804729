import React from 'react';
import { Link } from 'react-router-dom';
import './MenuItems.scss';

const MenuItems = ({ openMenuFn }) => {
  return (
    <>
      <nav className="navigation">
        <ul className="navigation__list">
          {/* <li className="navigation__list-item">Projekty</li> */}
          <li className="navigation__list-item">
            <Link
              to="/projekty-mieszkan"
              className="navigation__link"
              onClick={openMenuFn}
            >
              Mieszkania
            </Link>
          </li>
          <li className="navigation__list-item">
            <Link
              to="/projekty-domow"
              className="navigation__link"
              onClick={openMenuFn}
            >
              Domy
            </Link>
          </li>
          {/* <li className="navigation__list-item">Info</li> */}
          <li className="navigation__list-item">
            <Link
              to="/o-mnie"
              className="navigation__link"
              onClick={openMenuFn}
            >
              O mnie
            </Link>
          </li>
          <li className="navigation__list-item">
            <Link
              to="/oferta"
              className="navigation__link"
              onClick={openMenuFn}
            >
              Oferta
            </Link>
          </li>
          <li className="navigation__list-item">
            <Link
              to="/kontakt"
              className="navigation__link"
              onClick={openMenuFn}
            >
              Kontakt
            </Link>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default MenuItems;
