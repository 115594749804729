import React, { useState } from 'react';
import AppContext from '../../providers/AppDataContext';
import Lines from '../Lines/Lines';
import './Background.scss';

const Background = ({ images, alt, opacity }) => {
  const [showImage, setShowImage] = useState(false);

  return (
    <AppContext.Consumer>
      {(context) => (
        <div className="background">
          <img
            className="background__image"
            src={images.url}
            style={{
              left: `${context.backgroundPosX}px`,
              top: `${context.backgroundPosY}px`,
              opacity: `${showImage ? (opacity ? opacity : 0.5) : 0}`,
            }}
            onLoad={() => setShowImage(true)}
            alt={`${alt} tło`}
          />

          <Lines />
        </div>
      )}
    </AppContext.Consumer>
  );
};

export default Background;
