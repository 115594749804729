import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import './Main.scss';

import Home from '../../views/Home/Home';
import Projects from '../../views/Projects/Projects';
import Page from '../../views/Page/Page';
import Gallery from '../../views/Gallery/Gallery';
import Empty from '../../views/404/404';

const Main = () => {
  const location = useLocation();
  return (
    <main>
      <TransitionGroup className="main-transition-group">
        <CSSTransition
          key={location.key}
          timeout={{ enter: 300, exit: 300 }}
          classNames="fade"
        >
          <div className="route-section">
            <Routes location={location}>
              <Route exact path="/" element={<Home />} />
              <Route exact path="/projekty-mieszkan" element={<Projects />} />
              <Route path="/projekty-mieszkan/:id" element={<Gallery />} />
              <Route exact path="/projekty-domow" element={<Projects />} />
              <Route path="/projekty-domow/:id" element={<Gallery />} />
              <Route path="/o-mnie" element={<Page />} />
              <Route path="/oferta" element={<Page />} />
              <Route path="/kontakt" element={<Page />} />
              <Route path="*" element={<Empty />} />
            </Routes>
          </div>
        </CSSTransition>
      </TransitionGroup>
    </main>
  );
};

export default Main;
