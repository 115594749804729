import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Loader from '../../components/Loader/Loader';
import Lines from '../../components/Lines/Lines';
import AppContext from '../../providers/AppDataContext';

import './Home.scss';

const Home = () => {
  const { homePages } = useContext(AppContext).data;

  const [countSlides, setCountSlides] = useState(0);
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    const intervalId = setInterval(
      () =>
        setCountSlides((prev) =>
          prev === homePages.length - 1 ? 0 : prev + 1
        ),
      6000
    );
    return () => {
      clearInterval(intervalId);
    };
  }, [homePages]);

  return (
    <section className="home">
      <Helmet>
        <title>
          Milena Maciak - architektura wnętrz, projektowanie wnętrz, aranżacja,
          Warszawa
        </title>
        <meta
          name="description"
          content="Pracownia architektury wnętrz Milena Maciak Warszawa. Projekty aranżacje architektury wnętrz, architekt wnętrz, aranżacja wnętrz, architektura, Warszawa, meble."
        />
      </Helmet>
      <Lines />
      {showLoader ? <Loader /> : null}
      <AppContext.Consumer>
        {(context) => (
          <section className="home-slider">
            {homePages.map(({ title, description, background, alt }, index) => (
              <div key={index} className="home-slider__item">
                <img
                  className="home-slider__image"
                  style={{
                    left: `${context.backgroundPosX}px`,
                    top: `${context.backgroundPosY}px`,
                    opacity: `${
                      index === countSlides && !showLoader ? '0.5' : '0'
                    }`,
                    zIndex: `${
                      index === countSlides && !showLoader ? '-1' : '-2'
                    }`,
                  }}
                  onLoad={() =>
                    index === homePages.length - 1 && setShowLoader(false)
                  }
                  src={background.url}
                  alt={`${alt} zdj. ${index + 1}`}
                />

                <header
                  className="home-slider__header"
                  style={{
                    display: `${
                      countSlides === index && !showLoader ? 'block' : 'none'
                    }`,
                  }}
                >
                  <Link to={'/projekty-mieszkan'} className="home-slider__link">
                    <h2 className="home-slider__title">{title}</h2>
                    <p className="home-slider__paragraph">{description}</p>
                  </Link>
                </header>
              </div>
            ))}
          </section>
        )}
      </AppContext.Consumer>

      <section className="home-about">
        <h3 className="home-about__title">
          Projektowanie wnętrz mieszkalnych wraz z nadzorami nad realizacją -
          Architekt Wnętrz Warszawa
        </h3>
      </section>
    </section>
  );
};

export default Home;
