import React from 'react';
import { Helmet } from 'react-helmet';

import Lines from '../../components/Lines/Lines';
import './404.scss';

const Empty = () => {
  return (
    <>
      <Helmet>
        <title>404 | Milena Maciak Architektura wnętrz Warszawa</title>
      </Helmet>
      <Lines />
      <section className="empty">
        <h2 className="empty__title">404</h2>
        <div className="empty__paragraph">
          Strona, której szukasz nie istnieje :/
        </div>

        <footer className="empty__footer">
          @ Milena Maciak Architekt Wnętrz Warszawa
        </footer>
      </section>
    </>
  );
};

export default Empty;
