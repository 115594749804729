import React, { useState, useContext, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import AppDataContext from '../../providers/AppDataContext';
import Loader from '../../components/Loader/Loader';
import Lines from '../../components/Lines/Lines';
import Background from '../../components/Background/Background';
import './Projects.scss';

const Projects = () => {
  const [location, setLocation] = useState(null);
  const [countImages, setCountImages] = useState(0);
  const [showLoader, setShowLoader] = useState(true);

  const {
    data: { apartmentses, houseses },
    handleProjectsScroll,
    apartmentsLastScrollTop,
    housesLastScrollTop,
    backgroundPosX,
    backgroundPosY,
  } = useContext(AppDataContext);

  const locationPath = useLocation().pathname;

  if (!location) {
    if (locationPath.includes('projekty-mieszkan')) {
      setLocation('apartments');
    } else {
      setLocation('houses');
    }
  }

  let dataItems;
  let lastScrollTop;

  if (location === 'apartments') {
    dataItems = apartmentses;
    lastScrollTop = apartmentsLastScrollTop;
  } else {
    dataItems = houseses;
    lastScrollTop = housesLastScrollTop;
  }

  const [projectsScrollPosition, setProjectsScrollPosition] =
    useState(lastScrollTop);

  useEffect(() => {
    const projects = document.querySelector('.projects');
    projects.scrollTop = lastScrollTop;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const projects = document.querySelector('.projects');
    const handleProjectsScrollPosition = () => {
      setProjectsScrollPosition(projects.scrollTop);
    };
    projects.addEventListener('scroll', handleProjectsScrollPosition);

    return () => {
      handleProjectsScroll(location, projectsScrollPosition);
      projects.removeEventListener('scroll', handleProjectsScrollPosition);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectsScrollPosition]);

  const { background, alt } = dataItems[0];

  return (
    <>
      <Helmet>
        <title>
          {location === 'apartments'
            ? 'Projekty mieszkań - Milena Maciak - Architekt Wnętrz Warszawa'
            : 'Projekty domów - Milena Maciak - Architekt Wnętrz Warszawa'}
        </title>
        <meta
          name="description"
          content="Autorskie projekty wnętrz architektki Mileny Maciak w Warszawie i okolicach."
        />
      </Helmet>
      <Background images={background} alt={alt} opacity="0" />
      <Lines />
      <section className="projects">
        {showLoader ? <Loader /> : null}
        {dataItems.map((item, index) => (
          <React.Fragment key={item.id}>
            <div className="projects__images">
              <img
                className="projects__image"
                style={{
                  left: `${backgroundPosX}px`,
                  top: `${backgroundPosY}px`,
                  opacity: `${
                    index === countImages && !showLoader ? '0.5' : '0'
                  }`,
                  zIndex: `${
                    index === countImages && !showLoader ? '-1' : '-2'
                  }`,
                }}
                onLoad={() => index === 6 && setShowLoader(false)}
                src={item.background.url}
                alt={`${alt} zdj ${index + 1}`}
              />
            </div>
            <Link
              to={`${locationPath}/${index + 1}`}
              className="projects__link"
              onMouseEnter={() => setCountImages(index)}
            >
              <h2 className="projects__title">{item.title}</h2>
              <p className="projects__paragraph">{item.description}</p>
            </Link>
          </React.Fragment>
        ))}

        <footer className="projects__footer">
          @ Milena Maciak Architekt Wnętrz Warszawa
        </footer>
      </section>
    </>
  );
};

export default Projects;
