import React, { useContext } from 'react';
import { CSSTransition } from 'react-transition-group';
import AppDataContext from '../../providers/AppDataContext';

import MenuItems from './MenuItems';
import MenuHamburger from './MenuHamburger';
import MenuBackground from './MenuBackground';
import './Menu.scss';

const Menu = () => {
  const { isMenuOpen, openMenu } = useContext(AppDataContext);
  return (
    <>
      <CSSTransition
        in={isMenuOpen}
        timeout={300}
        classNames="menu"
        unmountOnExit
      >
        <>
          <MenuBackground />
          <MenuItems openMenuFn={openMenu} />
        </>
      </CSSTransition>
      <MenuHamburger onClick={openMenu} showMenu={isMenuOpen} />
    </>
  );
};

export default Menu;
