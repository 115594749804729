import React, { useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import AppDataContext from './providers/AppDataContext';

import Main from './components/Main/Main';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import Menu from './components/Menu/Menu';
import Loader from './components/Loader/Loader';

const App = () => {
  const fetchURLGraphCMS = `${process.env.REACT_APP_API_URL}`;
  const fetchSchema = `${process.env.REACT_APP_API_SCHEMA}`;

  const [fetchData, setFetchData] = useState({
    loading: true,
    error: null,
    data: null,
  });

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [backgroundPosition, setBackgroundPosition] = useState({
    backgroundPosX: window.innerWidth / 2,
    backgroundPosY: window.innerHeight / 2,
  });
  const [projectsScroll, setProjectsScroll] = useState({
    apartmentsLastScrollTop: 0,
    housesLastScrollTop: 0,
  });

  useEffect(() => {
    fetchDataGraphCMS(fetchURLGraphCMS, fetchSchema);
    document.addEventListener('mousemove', handleBackgroundPosition, false);
    window.addEventListener('resize', handleWindowResize, false);

    return () => {
      document.removeEventListener(
        'mousemove',
        handleBackgroundPosition,
        false
      );
      window.removeEventListener('resize', handleWindowResize, false);
    };
  }, [fetchURLGraphCMS, fetchSchema]);

  const fetchDataGraphCMS = (fetchURL, fetchSchema) => {
    fetch(fetchURL, {
      method: 'POST',
      body: JSON.stringify({
        query: fetchSchema,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setFetchData({ data: data.data, error: null, loading: false });
      })
      .catch((err) => {
        setFetchData({ data: null, error: err, loading: false });
        console.log(err);
      });
  };

  const handleIsOpenMenu = () => {
    setIsMenuOpen((isMenuOpen) => !isMenuOpen);
  };

  const handleBackgroundPosition = (event) => {
    const { clientX, clientY } = event;
    const centerX = window.innerWidth / 2;
    const centerY = window.innerHeight / 2;

    const posX = centerX + (clientX - centerX) / (centerX / 30);
    const posY = centerY + (clientY - centerY) / (centerY / 15);

    setBackgroundPosition({
      backgroundPosX: posX.toFixed(2),
      backgroundPosY: posY.toFixed(2),
    });
  };

  const handleWindowResize = () => {
    setBackgroundPosition({
      backgroundPosX: window.innerWidth / 2,
      backgroundPosY: window.innerHeight / 2,
    });
  };

  const handleProjectsScroll = (name, scrollTop) => {
    if (name === 'apartments') {
      setProjectsScroll((projectsScroll) => ({
        ...projectsScroll,
        apartmentsLastScrollTop: scrollTop,
      }));
    } else {
      setProjectsScroll((projectsScroll) => ({
        ...projectsScroll,
        housesLastScrollTop: scrollTop,
      }));
    }
  };

  const contextElements = {
    ...fetchData,
    ...backgroundPosition,
    ...projectsScroll,
    isMenuOpen: isMenuOpen,
    openMenu: handleIsOpenMenu,
    handleProjectsScroll: handleProjectsScroll,
  };
  // console.log(contextElements);
  if (!fetchData.data || fetchData.loading) {
    return <Loader />;
  }

  return (
    <>
      <AppDataContext.Provider value={contextElements}>
        <BrowserRouter>
          <Main />
          <Header />
          <Footer />
          <Menu />
        </BrowserRouter>
      </AppDataContext.Provider>
    </>
  );
};

export default App;
