import React, { useContext, useState } from 'react';
import { useLocation } from 'react-router';
import Markdown from 'react-markdown';
import { Helmet } from 'react-helmet';

import AppContext from '../../providers/AppDataContext';
import Background from '../../components/Background/Background';
import Map from '../../components/Map/Map';
import './Page.scss';

const Page = () => {
  const [location, setLocation] = useState(null);

  const locationPath = useLocation().pathname;
  const { abouts, offers, contacts } = useContext(AppContext).data;

  if (!location) {
    if (locationPath.includes('o-mnie')) {
      setLocation('o-mnie');
    } else if (locationPath.includes('oferta')) {
      setLocation('oferta');
    } else {
      setLocation('contact');
    }
  }

  const { title, paragraph, background, alt, map } =
    (location === 'o-mnie' && abouts[0]) ||
    (location === 'oferta' && offers[0]) ||
    contacts[0];

  return (
    <>
      <Helmet>
        <title>{title} - Milena Maciak Architektura Wnętrz Warszawa</title>
        <meta
          name="description"
          content="Dodatkowe informacje na temat Pracowni Architektury Wnętrz Milena Maciak Warszawa."
        />
      </Helmet>
      <Background images={background} alt={alt} opacity="0.1" />
      <section className="page">
        <h2 className="page__title">{title}</h2>
        <article className="page__paragraph">
          <Markdown children={paragraph} />
        </article>
        {location === 'contact' ? <Map /> : null}

        <footer className="page__footer">
          @ Milena Maciak Architekt Wnętrz Warszawa
        </footer>
      </section>
    </>
  );
};

export default Page;
