import React from 'react';
import './Lines.scss';

const Lines = () => {
  return (
    <div className="lines">
      <div className="lines__line"></div>
      <div className="lines__line"></div>
      <div className="lines__line"></div>
      <div className="lines__line"></div>
      <div className="lines__line"></div>
      <div className="lines__line"></div>
    </div>
  );
};

export default Lines;
