import React from 'react';
import './MenuHamburger.scss';

const MenuHamburger = ({ showMenu, onClick }) => {
  return (
    <div
      className={
        showMenu
          ? 'navigation__button navigation__button--active'
          : 'navigation__button'
      }
      onClick={onClick}
    >
      <div className="navigation__button-line navigation__button-line--1"></div>
      <div className="navigation__button-line navigation__button-line--2"></div>
      <div className="navigation__button-line navigation__button-line--3"></div>
    </div>
  );
};

export default MenuHamburger;
