import React from 'react';
import { Link } from 'react-router-dom';
import './Header.scss';

const Header = () => {
  return (
    <header className="header">
      <h1 className="header__title">
        <Link className="header__link" to="/">
          Milena Maciak
        </Link>
      </h1>
      <span className="header__subtitle">Architekt Wnętrz</span>
    </header>
  );
};

export default Header;
