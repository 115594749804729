import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useParams, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import AppContext from '../../providers/AppDataContext';
import Loader from '../../components/Loader/Loader';
import Background from '../../components/Background/Background';
import './Gallery.scss';

const Gallery = () => {
  const [location, setLocation] = useState(null);
  const [countImages, setCountImages] = useState(0);
  const [showLoader, setShowLoader] = useState(true);
  const [mousePosition, setMousePosition] = useState([-100, -100]);
  const [arrow, setArrow] = useState('');

  let { id } = useParams();

  const locationPath = useLocation().pathname;
  if (!location) {
    if (locationPath.includes('projekty-mieszkan')) {
      setLocation('apartments');
    } else {
      setLocation('houses');
    }
  }
  const { apartmentses, houseses } = useContext(AppContext).data;
  const items = location === 'apartments' ? apartmentses : houseses;
  const { title, description, publication, fot, images, background, alt } =
    items[id - 1] || items[0];

  const goToNextSlide = (e) => {
    if (
      e.clientY > window.innerHeight / 6 &&
      e.clientY < window.innerHeight - window.innerHeight / 6
    ) {
      if (e.clientX > window.innerWidth / 2) {
        if (countImages === images.length - 1) {
          return setCountImages(0);
        }
        setCountImages(countImages + 1);
      } else {
        if (countImages === 0) {
          return setCountImages(images.length - 1);
        }
        setCountImages(countImages - 1);
      }
    }
  };

  const showArrow = (e) => {
    if (
      e.clientY > window.innerHeight / 6 &&
      e.clientY < window.innerHeight - window.innerHeight / 6
    ) {
      setMousePosition([e.clientX, e.clientY]);
      if (e.clientX < window.innerWidth / 2) {
        setArrow('left');
      } else {
        setArrow('right');
      }
    } else {
      setMousePosition([-100, -100]);
      setArrow('');
    }
  };

  return (
    <>
      <Helmet>
        <title>{`${title} ${description} / Milena Maciak Architekt Wnętrz Warszawa`}</title>
        <meta
          name="description"
          content="Autorski projekt wnętrz i mebli wykonany na zlecenie klienta wraz z nadzorem nad projektem."
        />
      </Helmet>
      <Background images={background} alt={alt} opacity="0.1" />
      {showLoader ? <Loader /> : null}
      <section
        className="gallery"
        onMouseMove={showArrow}
        onClick={goToNextSlide}
        style={mousePosition[0] >= 0 ? { cursor: 'none' } : null}
      >
        <header className="gallery__label">
          <p className="gallery__paragraph gallery__paragraph--count">
            {`${countImages + 1}/${images.length}`}
          </p>
          <h2 className="gallery__title">{title}</h2>
          <p className="gallery__paragraph">{description}</p>
          <p className="gallery__paragraph">{fot}</p>
          <p className="gallery__paragraph">{publication}</p>
        </header>

        <div className="gallery__images">
          {images.map(({ id, url }, index) => (
            <img
              key={id}
              className="gallery__image"
              style={
                index === countImages && !showLoader
                  ? { opacity: '1' }
                  : { opacity: '0' }
              }
              onLoad={() => index === 0 && setShowLoader(false)}
              src={url}
              alt={`${alt} zdj. ${index + 1}`}
            />
          ))}
        </div>

        <div
          className="gallery__arrow"
          style={{
            left: `${mousePosition[0]}px`,
            top: `${mousePosition[1]}px`,
          }}
        >
          {arrow ? (arrow === 'left' ? '⟵' : '⟶') : ''}
        </div>

        <footer className="gallery__footer">
          <p>Oferta</p>
          <Link to="/projekty-mieszkan" className="navigation__link">
            Projektowanie wnętrz mieszkalnych wraz z nadzorami nad realizacją
          </Link>
          <p>Informacje główne</p>
          <Link to="/o-mnie" className="navigation__link">
            Architekt wnętrz
          </Link>
          <Link to="/o-mnie" className="navigation__link">
            Moje studio
          </Link>
          <Link to="/projekty-domow" className="navigation__link">
            Projekty i Realizacje
          </Link>
          <Link to="/kontakt" className="navigation__link">
            Kontakt
          </Link>
          <p className="gallery__footer-paragraph--small">
            @ Milena Maciak Architekt Wnętrz Warszawa
          </p>
        </footer>
      </section>
    </>
  );
};

export default Gallery;
