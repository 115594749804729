import React from 'react';
import './Map.scss';

const Map = () => {
  return (
    <figure className="map">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2447.269802359009!2d21.012430515677888!3d52.16578787974875!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471932e93f007b49%3A0x8913c761301f7f57!2sMilena%20Maciak%20Architekt%20Wn%C4%99trz%20Warszawa!5e0!3m2!1spl!2spl!4v1663674652411!5m2!1spl!2spl"
        width="600"
        height="350"
        title="Google map"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </figure>
  );
};

export default Map;
